define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ox+xH0Vr",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"wrappedRowArray\"]]],null,{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\"],[[22,1,[]],[23,[\"columns\"]],[23,[\"columnMetaCache\"]],[23,[\"rowMetaCache\"]],[23,[\"canSelect\"]],[23,[\"rowSelectionMode\"]],[23,[\"checkboxSelectionMode\"]]]],{\"statements\":[[4,\"if\",[[24,3]],null,{\"statements\":[[0,\"      \"],[14,3,[[27,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"row\"],[[22,2,[\"rowValue\"]],[22,2,[\"rowMeta\"]],[22,2,[\"cells\"]],[22,2,[\"rowSelectionMode\"]],[27,\"component\",[\"ember-tr\"],[[\"api\"],[[22,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"ember-tr\",null,[[\"api\"],[[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tfoot/template.hbs"
    }
  });

  _exports.default = _default;
});