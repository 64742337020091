define("ember-table/components/ember-tr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFLgZWoE",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"cells\"]]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[4,\"if\",[[23,[\"isHeader\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[27,\"hash\",null,[[\"columnValue\",\"columnMeta\",\"sorts\",\"sendUpdateSort\",\"cell\"],[[22,1,[\"columnValue\"]],[22,1,[\"columnMeta\"]],[22,1,[\"sorts\"]],[22,1,[\"sendUpdateSort\"]],[27,\"component\",[\"ember-th\"],[[\"api\"],[[22,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,2,[[27,\"hash\",null,[[\"api\",\"cellValue\",\"cellMeta\",\"columnValue\",\"columnMeta\",\"rowValue\",\"rowMeta\",\"cell\"],[[22,1,[]],[22,1,[\"cellValue\"]],[22,1,[\"cellMeta\"]],[22,1,[\"columnValue\"]],[22,1,[\"columnMeta\"]],[22,1,[\"rowValue\"]],[22,1,[\"rowMeta\"]],[27,\"component\",[\"ember-td\"],[[\"api\"],[[22,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isHeader\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"ember-th\",null,[[\"api\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"ember-td\",null,[[\"api\"],[[22,1,[]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tr/template.hbs"
    }
  });

  _exports.default = _default;
});