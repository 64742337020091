define("ember-table/components/ember-tbody/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yIoCImGB",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&inverse\",\"&default\"],\"statements\":[[4,\"vertical-collection\",[[23,[\"wrappedRows\"]]],[[\"containerSelector\",\"estimateHeight\",\"key\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"firstReached\",\"lastReached\",\"firstVisibleChanged\",\"lastVisibleChanged\",\"idForFirstItem\"],[[23,[\"_containerSelector\"]],[23,[\"estimateRowHeight\"]],[23,[\"key\"]],[23,[\"staticHeight\"]],[23,[\"bufferSize\"]],[23,[\"renderAll\"]],[23,[\"firstReached\"]],[23,[\"lastReached\"]],[23,[\"firstVisibleChanged\"]],[23,[\"lastVisibleChanged\"]],[23,[\"idForFirstItem\"]]]],{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\"],[[22,1,[]],[23,[\"columns\"]],[23,[\"columnMetaCache\"]],[23,[\"rowMetaCache\"]],[23,[\"canSelect\"]],[23,[\"rowSelectionMode\"]],[23,[\"checkboxSelectionMode\"]]]],{\"statements\":[[4,\"if\",[[24,4]],null,{\"statements\":[[0,\"      \"],[14,4,[[27,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"row\"],[[22,2,[\"rowValue\"]],[22,2,[\"rowMeta\"]],[22,2,[\"cells\"]],[22,2,[\"rowSelectionMode\"]],[27,\"component\",[\"ember-tr\"],[[\"api\"],[[22,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"ember-tr\",null,[[\"api\"],[[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[23,[\"shouldYieldToInverse\"]]],null,{\"statements\":[[0,\"  \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tbody/template.hbs"
    }
  });

  _exports.default = _default;
});