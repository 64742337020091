define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vyrPptqX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isSorted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[\"et-sort-indicator \",[27,\"if\",[[23,[\"isSortedAsc\"]],\"is-ascending\",\"is-descending\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,[\"columnMeta\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isMultiSorted\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"sortIndex\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isSortable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"et-sort-toggle et-speech-only\"],[9],[0,\"Toggle Sort\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});