define('ember-cli-gravatar/services/gravatar', ['exports', 'md5'], function (exports, _md) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ajax = Ember.$.ajax,
      Promise = Ember.RSVP.Promise,
      Service = Ember.Service;
  exports.default = Service.extend({
    hasGravatar: function hasGravatar(email, secure, gravatarHost) {
      if (!email) {
        throw new Error('expecting email');
      }

      var hash = (0, _md.default)(email);

      return new Promise(function (resolve) {
        ajax('http' + (secure ? 's' : '') + '://' + gravatarHost + '/avatar/' + hash + '?d=404', {
          complete: function complete() {
            resolve(true);
          },
          error: function error() {
            resolve(false);
          }
        });
      });
    }
  });
});