define("ember-table/-private/column-tree", ["exports", "ember-table/-private/utils/observer", "ember-raf-scheduler", "ember-table/-private/meta-cache", "ember-table/-private/utils/array", "ember-table/-private/utils/sort", "ember-table/-private/utils/element", "ember-table/-private/utils/reorder-indicators", "ember-table/-private/utils/ember"], function (_exports, _observer, _emberRafScheduler, _metaCache, _array, _sort, _element, _reorderIndicators, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WIDTH_CONSTRAINT = _exports.FILL_MODE = _exports.RESIZE_MODE = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var SCROLL_THRESHOLD = 50;
  var DEFAULT_COLUMN_WIDTH = 100;
  var DEFAULT_MIN_WIDTH = 50;
  var DEFAULT_MAX_WIDTH = Infinity;
  var LOOP_COUNT_GUARD = 500;
  var RESIZE_MODE = {
    STANDARD: 'standard',
    FLUID: 'fluid'
  };
  _exports.RESIZE_MODE = RESIZE_MODE;
  var FILL_MODE = {
    EQUAL_COLUMN: 'equal-column',
    FIRST_COLUMN: 'first-column',
    LAST_COLUMN: 'last-column',
    NTH_COLUMN: 'nth-column'
  };
  _exports.FILL_MODE = FILL_MODE;
  var WIDTH_CONSTRAINT = {
    NONE: 'none',
    EQ_CONTAINER: 'eq-container',
    GTE_CONTAINER: 'gte-container',
    LTE_CONTAINER: 'lte-container'
  };
  /**
   * Divides x into y pieces where all y pieces are rounded
   * and sum to x. Assumes x is already rounded.
   * Returns a list of the pieces.
   *
   * For example:
   * 10 / 3 => [4, 3, 3]
   * -11 / 2 => [-6, -5]
   */

  _exports.WIDTH_CONSTRAINT = WIDTH_CONSTRAINT;

  function divideRounded(x, n) {
    var neg = x < 0 === n < 0 ? 1 : -1;
    n = Math.abs(n);
    x = Math.abs(x);
    var z = Math.floor(x / n);
    var err = x - n * z;
    var result = Array(n);
    result.fill(neg * (z + 1), 0, err);
    result.fill(neg * z, err);
    return result;
  }

  var TableColumnMeta = Ember.Object.extend({
    // If no width is set on the column itself, we cache a temporary width on the
    // meta object. This is set to the default width.
    _width: DEFAULT_COLUMN_WIDTH,
    isLeaf: Ember.computed.readOnly('_node.isLeaf'),
    isFixed: Ember.computed.readOnly('_node.isFixed'),
    isSortable: Ember.computed.readOnly('_node.isSortable'),
    isResizable: Ember.computed.readOnly('_node.isResizable'),
    isReorderable: Ember.computed.readOnly('_node.isReorderable'),
    width: Ember.computed.readOnly('_node.width'),
    offsetLeft: Ember.computed.readOnly('_node.offsetLeft'),
    offsetRight: Ember.computed.readOnly('_node.offsetRight'),
    rowSpan: Ember.computed('isLeaf', '_node.{depth,tree.root.maxChildDepth}', function () {
      if (!this.get('isLeaf')) {
        return 1;
      }

      var maxDepth = this.get('_node.tree.root.maxChildDepth');
      var depth = this.get('_node.depth');
      return maxDepth - (depth - 1);
    }),
    columnSpan: Ember.computed('isLeaf', '_node.leaves.length', function () {
      if (this.get('isLeaf')) {
        return 1;
      }

      return this.get('_node.leaves.length');
    }),
    index: Ember.computed('isLeaf', '_node.offsetIndex', function () {
      if (this.get('isLeaf')) {
        return this.get('_node.offsetIndex');
      }
    }),
    sortIndex: Ember.computed('_node.{tree.sorts.[],column.valuePath}', function () {
      var valuePath = this.get('_node.column.valuePath');
      var sorts = this.get('_node.tree.sorts');
      var sortIndex = 0;

      for (var i = 0; i < Ember.get(sorts, 'length'); i++) {
        var sorting = (0, _array.objectAt)(sorts, i);

        if (Ember.get(sorting, 'valuePath') === valuePath) {
          sortIndex = i + 1;
          break;
        }
      }

      return sortIndex;
    }),
    isSorted: Ember.computed.gt('sortIndex', 0),
    isMultiSorted: Ember.computed.gt('_node.tree.sorts.length', 1),
    isSortedAsc: Ember.computed('_node.tree.sorts.[]', 'sortIndex', function () {
      var sortIndex = this.get('sortIndex');
      var sorts = this.get('_node.tree.sorts');
      return Ember.get((0, _array.objectAt)(sorts, sortIndex - 1), 'isAscending');
    })
  });
  /**
    Single node of a ColumnTree
  */

  var ColumnTreeNode = Ember.Object.extend({
    _subcolumnNodes: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var tree = Ember.get(this, 'tree');
      var parent = Ember.get(this, 'parent');
      var column = Ember.get(this, 'column');

      if (!parent) {
        this.isRoot = true;
      } else {
        var meta = (0, _metaCache.getOrCreate)(column, Ember.get(tree, 'columnMetaCache'), TableColumnMeta);
        Ember.set(meta, '_node', this);

        meta.registerElement = function () {
          return _this.registerElement.apply(_this, arguments);
        };

        meta.startResize = function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return tree.startResize.apply(tree, [_this].concat(args));
        };

        meta.updateResize = function () {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }

          return tree.updateResize.apply(tree, [_this].concat(args));
        };

        meta.endResize = function () {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }

          return tree.endResize.apply(tree, [_this].concat(args));
        };

        meta.startReorder = function () {
          for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
            args[_key4] = arguments[_key4];
          }

          return tree.startReorder.apply(tree, [_this].concat(args));
        };

        meta.updateReorder = function () {
          for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
            args[_key5] = arguments[_key5];
          }

          return tree.updateReorder.apply(tree, [_this].concat(args));
        };

        meta.endReorder = function () {
          for (var _len6 = arguments.length, args = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
            args[_key6] = arguments[_key6];
          }

          return tree.endReorder.apply(tree, [_this].concat(args));
        }; // Changes to the value directly should properly update all computeds on this
        // node, but we need to manually propagate changes upwards to notify any other
        // watchers


        this._notifyMaxChildDepth = function () {
          return (0, _ember.notifyPropertyChange)(parent, 'maxChildDepth');
        };

        this._notifyLeaves = function () {
          return (0, _ember.notifyPropertyChange)(parent, 'leaves');
        };

        (0, _observer.addObserver)(this, 'maxChildDepth', this._notifyMaxChildDepth);
        (0, _observer.addObserver)(this, 'leaves.[]', this._notifyLeaves);
      }
    },
    destroy: function destroy() {
      this.cleanSubcolumnNodes();

      this._super.apply(this, arguments);
    },

    /**
      Fully destroys the child nodes in the event that they change or that this
      node is destroyed. If children are not destroyed, they will leak memory due
      to dangling references in Ember Meta.
    */
    cleanSubcolumnNodes: function cleanSubcolumnNodes() {
      if (this._subcolumnNodes !== null) {
        this._subcolumnNodes.forEach(function (n) {
          return n.destroy();
        });

        this._subcolumnNodes = null;
      }
    },
    subcolumnNodes: Ember.computed('column.subcolumns.[]', function () {
      this.cleanSubcolumnNodes();

      if (Ember.get(this, 'isLeaf')) {
        return;
      }

      var tree = Ember.get(this, 'tree');
      var parent = this;
      this._subcolumnNodes = Ember.A(Ember.get(this, 'column.subcolumns').map(function (column) {
        return ColumnTreeNode.create({
          column: column,
          tree: tree,
          parent: parent
        });
      }));
      return this._subcolumnNodes;
    }),
    isLeaf: Ember.computed('column.subcolumns.[]', 'isRoot', function () {
      var subcolumns = Ember.get(this, 'column.subcolumns');

      if (Ember.get(this, 'isRoot')) {
        return false;
      }

      return !subcolumns || Ember.get(subcolumns, 'length') === 0;
    }),
    isSortable: Ember.computed('column.isSortable', 'tree.enableSort', function () {
      var enableSort = Ember.get(this, 'tree.enableSort');
      var valuePath = Ember.get(this, 'column.valuePath');
      var isSortable = Ember.get(this, 'column.isSortable');
      var isLeaf = Ember.get(this, 'isLeaf');
      return isLeaf === true && enableSort !== false && isSortable !== false && typeof valuePath === 'string';
    }),
    isReorderable: Ember.computed('column.isReorderable', 'tree.enableReorder', function () {
      var enableReorder = Ember.get(this, 'tree.enableReorder');
      var isReorderable = Ember.get(this, 'column.isReorderable');
      return enableReorder !== false && isReorderable !== false;
    }),
    isResizable: Ember.computed('column.isResizable', 'tree.enableResize', function () {
      var isLeaf = Ember.get(this, 'isLeaf');

      if (isLeaf) {
        var enableResize = Ember.get(this, 'tree.enableResize');
        var isResizable = Ember.get(this, 'column.isResizable');
        return enableResize !== false && isResizable !== false;
      } else {
        var subcolumns = Ember.get(this, 'subcolumnNodes');
        return subcolumns.some(function (s) {
          return Ember.get(s, 'isResizable');
        });
      }
    }),
    isFixed: Ember.computed('parent.{isFixed,isRoot}', 'column.isFixed', function () {
      if (Ember.get(this, 'parent.isRoot')) {
        return Ember.get(this, 'column.isFixed');
      }

      return Ember.get(this, 'parent.isFixed');
    }),
    depth: Ember.computed('parent.depth', function () {
      if (Ember.get(this, 'parent')) {
        return Ember.get(this, 'parent.depth') + 1;
      }

      return 0;
    }),
    maxChildDepth: Ember.computed('isLeaf', 'subcolumns.@each.depth', function () {
      if (Ember.get(this, 'isLeaf')) {
        return Ember.get(this, 'depth');
      }

      return Math.max.apply(Math, _toConsumableArray(Ember.get(this, 'subcolumnNodes').map(function (s) {
        return Ember.get(s, 'maxChildDepth');
      })));
    }),
    leaves: Ember.computed('isLeaf', 'subcolumnNodes.{[],@each.leaves}', function () {
      if (Ember.get(this, 'isLeaf')) {
        return [this];
      }

      return Ember.get(this, 'subcolumnNodes').reduce(function (leaves, subcolumn) {
        leaves.push.apply(leaves, _toConsumableArray(Ember.get(subcolumn, 'leaves')));
        return leaves;
      }, Ember.A());
    }),
    minWidth: Ember.computed('column.minWidth', function () {
      if (Ember.get(this, 'isLeaf')) {
        var columnMinWidth = Ember.get(this, 'column.minWidth');
        return typeof columnMinWidth === 'number' ? columnMinWidth : DEFAULT_MIN_WIDTH;
      }

      return Ember.get(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
        var subcolumnMinWidth = Ember.get(subcolumn, 'minWidth');
        return sum + subcolumnMinWidth;
      }, 0);
    }),
    maxWidth: Ember.computed('column.minWidth', function () {
      if (Ember.get(this, 'isLeaf')) {
        var columnMaxWidth = Ember.get(this, 'column.maxWidth');
        return typeof columnMaxWidth === 'number' ? columnMaxWidth : DEFAULT_MAX_WIDTH;
      }

      return Ember.get(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
        var subcolumnMaxWidth = Ember.get(subcolumn, 'maxWidth');
        return sum + subcolumnMaxWidth;
      }, 0);
    }),
    width: Ember.computed('isLeaf', 'subcolumnNodes.@each.width', 'column.width', {
      get: function get() {
        if (Ember.get(this, 'isLeaf')) {
          var column = Ember.get(this, 'column');
          var columnWidth = Ember.get(column, 'width');

          if (typeof columnWidth === 'number') {
            return columnWidth;
          } else {
            var meta = Ember.get(this, 'tree.columnMetaCache').get(column);
            return Ember.get(meta, '_width');
          }
        }

        return Ember.get(this, 'subcolumnNodes').reduce(function (sum, subcolumn) {
          var subcolumnWidth = Ember.get(subcolumn, 'width');
          return sum + subcolumnWidth;
        }, 0);
      },
      set: function set(key, newWidth) {
        var oldWidth = Ember.get(this, 'width');
        var isResizable = Ember.get(this, 'isResizable');

        if (!isResizable) {
          return oldWidth;
        }

        var delta = newWidth - oldWidth;
        var minWidth = Ember.get(this, 'minWidth');
        var maxWidth = Ember.get(this, 'maxWidth');
        delta = Math.max(Math.min(oldWidth + delta, maxWidth), minWidth) - oldWidth;

        if (delta === 0) {
          return oldWidth;
        }

        if (Ember.get(this, 'isLeaf')) {
          var column = Ember.get(this, 'column');
          var columnWidth = Ember.get(column, 'width');
          var width = oldWidth + delta;

          if (typeof columnWidth === 'number') {
            Ember.set(column, 'width', width);
          } else {
            var meta = Ember.get(this, 'tree.columnMetaCache').get(column);
            Ember.set(meta, '_width', width);
          }

          return width;
        } else {
          var subcolumns = Ember.get(this, 'subcolumnNodes'); // Delta can only be rendered at a pixel level of precision in tables in
          // some browsers, so we round and distribute the remainder as well. We also
          // don't know when we may hit a constraint (e.g. minWidth) so we have to do
          // this repeatedly. We take the largest chunk we can and try to fit it into
          // each piece in a loop.
          // We distribute chunks to the columns starting from the column with the
          // smallest width to the column with the largest width.

          var sortedSubcolumns = subcolumns.sortBy('width').filter(function (n) {
            return Ember.get(n, 'isResizable');
          }).reverse();
          var loopCount = 0;
          var prevDelta = 0;
          delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta);

          while (delta !== 0) {
            var deltaChunks = divideRounded(delta, sortedSubcolumns.length);

            for (var i = 0; i < deltaChunks.length; i++) {
              var subcolumn = sortedSubcolumns[i];
              var deltaChunk = deltaChunks[i];

              var _oldWidth = Ember.get(subcolumn, 'width');

              var targetWidth = _oldWidth + deltaChunk;
              Ember.set(subcolumn, 'width', targetWidth);

              var _newWidth = Ember.get(subcolumn, 'width'); // subtract the amount that changed, if any


              delta -= _newWidth - _oldWidth;

              if (delta === 0) {
                break;
              }
            }

            delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta); // If we weren't able to change the delta at all, then we hit a hard
            // barrier. This can happen when a table has too many columns to size
            // down, for instance.

            if (prevDelta === delta) {
              break;
            }

            prevDelta = delta;
            loopCount++;

            if (loopCount > LOOP_COUNT_GUARD) {
              throw new Error('loop count exceeded guard while distributing width');
            }
          }

          return Ember.get(this, 'width');
        }
      }
    }),
    offsetIndex: Ember.computed('parent.{offsetIndex,subcolumnNodes.[]}', function () {
      var parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = Ember.get(parent, 'subcolumnNodes');
      var offsetIndex = Ember.get(parent, 'offsetIndex');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = subcolumns[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var column = _step.value;

          if (column === this) {
            break;
          }

          offsetIndex += 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return offsetIndex;
    }),
    offsetLeft: Ember.computed('parent.{offsetLeft,width}', function () {
      var parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = Ember.get(parent, 'subcolumnNodes');
      var offsetLeft = Ember.get(parent, 'offsetLeft');
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = subcolumns[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var column = _step2.value;

          if (column === this) {
            break;
          }

          offsetLeft += Ember.get(column, 'width');
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return offsetLeft;
    }),
    offsetRight: Ember.computed('parent.{offsetRight,width}', function () {
      var parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      var subcolumns = Ember.get(parent, 'subcolumnNodes').slice().reverse();
      var offsetRight = Ember.get(parent, 'offsetRight');
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = subcolumns[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var column = _step3.value;

          if (column === this) {
            break;
          }

          offsetRight += Ember.get(column, 'width');
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return offsetRight;
    }),
    registerElement: function registerElement(element) {
      this.element = element;
    }
  });

  var _default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.token = new _emberRafScheduler.Token();
      this._sortColumnsByFixed = this.sortColumnsByFixed.bind(this);
      this._ensureWidthConstraint = this.ensureWidthConstraint.bind(this);
      (0, _observer.addObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.addObserver)(this, 'widthConstraint', this._ensureWidthConstraint);
    },
    destroy: function destroy() {
      this.token.cancel();
      Ember.get(this, 'root').destroy();
      (0, _observer.removeObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.removeObserver)(this, 'widthConstraint', this._ensureWidthConstraint);

      this._super.apply(this, arguments);
    },
    root: Ember.computed('columns', function () {
      var columns = Ember.get(this, 'columns');
      return ColumnTreeNode.create({
        column: {
          subcolumns: columns
        },
        tree: this
      });
    }),
    rows: Ember.computed('root.{maxChildDepth,leaves.[]}', function () {
      var rows = Ember.A();
      var root = Ember.get(this, 'root');
      var maxDepth = Ember.get(root, 'maxChildDepth');
      var previousLevel = [root];

      for (var i = 0; i < maxDepth; i++) {
        var currentLevel = previousLevel.reduce(function (children, node) {
          if (!Ember.get(node, 'isLeaf')) {
            children.push.apply(children, _toConsumableArray(Ember.get(node, 'subcolumnNodes')));
          }

          return children;
        }, []);
        var columns = currentLevel.map(function (node) {
          return Ember.get(node, 'column');
        });
        rows.pushObject(Ember.A(columns));
        previousLevel = currentLevel;
      }

      return rows;
    }),
    leaves: Ember.computed('root.leaves.[]', function () {
      return Ember.A(Ember.get(this, 'root.leaves').map(function (n) {
        return n.column;
      }));
    }),
    leftFixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filterBy('isFixed', 'left');
    }),
    rightFixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filterBy('isFixed', 'right');
    }),
    unfixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filter(function (s) {
        return !Ember.get(s, 'isFixed');
      });
    }),
    scrollBounds: Ember.computed('leftFixedNodes.@each.width', 'rightFixedNodes.@each.width', function () {
      var _getInnerClientRect = (0, _element.getInnerClientRect)(this.container),
          containerLeft = _getInnerClientRect.left,
          containerRight = _getInnerClientRect.right;

      containerLeft += Ember.get(this, 'leftFixedNodes').reduce(function (sum, n) {
        return sum + Ember.get(n, 'width');
      }, 0);
      containerRight -= Ember.get(this, 'rightFixedNodes').reduce(function (sum, n) {
        return sum + Ember.get(n, 'width');
      }, 0);
      return {
        containerLeft: containerLeft,
        containerRight: containerRight
      };
    }),
    sortColumnsByFixed: function sortColumnsByFixed() {
      // disable observer
      if (this._isSorting) {
        return;
      }

      this._isSorting = true;
      var columns = Ember.get(this, 'columns');
      var sorted = (0, _sort.mergeSort)(columns, function (a, b) {
        var aIsFixed = Ember.get(a, 'isFixed');
        var bIsFixed = Ember.get(b, 'isFixed');
        var aValue = aIsFixed === 'left' ? -1 : aIsFixed === 'right' ? 1 : 0;
        var bValue = bIsFixed === 'left' ? -1 : bIsFixed === 'right' ? 1 : 0;
        return aValue - bValue;
      });
      var alreadySorted = true;

      for (var i = 0; i < columns.length; i++) {
        if (sorted[i] !== columns[i]) {
          alreadySorted = false;
          break;
        }
      }

      if (!alreadySorted) {
        _array.splice.apply(void 0, [columns, 0, sorted.length].concat(_toConsumableArray(sorted)));
      }

      this._isSorting = false;
    },
    ensureWidthConstraint: function ensureWidthConstraint() {
      if (!this.container) {
        return;
      }

      var containerWidthAdjustment = Ember.get(this, 'containerWidthAdjustment') || 0;
      var containerWidth = (0, _element.getInnerClientRect)(this.container).width * this.scale + containerWidthAdjustment;
      var treeWidth = Ember.get(this, 'root.width');
      var columns = Ember.get(this, 'root.subcolumnNodes');
      var widthConstraint = Ember.get(this, 'widthConstraint');
      var fillMode = Ember.get(this, 'fillMode');
      var fillColumnIndex = Ember.get(this, 'fillColumnIndex');

      if (widthConstraint === WIDTH_CONSTRAINT.EQ_CONTAINER && treeWidth !== containerWidth || widthConstraint === WIDTH_CONSTRAINT.LTE_CONTAINER && treeWidth > containerWidth || widthConstraint === WIDTH_CONSTRAINT.GTE_CONTAINER && treeWidth < containerWidth) {
        var delta = containerWidth - treeWidth;

        if (fillMode === FILL_MODE.EQUAL_COLUMN) {
          Ember.set(this, 'root.width', containerWidth);
        } else if (fillMode === FILL_MODE.FIRST_COLUMN) {
          this.resizeColumn(0, delta);
        } else if (fillMode === FILL_MODE.LAST_COLUMN) {
          this.resizeColumn(columns.length - 1, delta);
        } else if (fillMode === FILL_MODE.NTH_COLUMN) {
          (false && !(!Ember.isEmpty(fillColumnIndex)) && Ember.assert("fillMode 'nth-column' must have a fillColumnIndex defined", !Ember.isEmpty(fillColumnIndex)));
          this.resizeColumn(fillColumnIndex, delta);
        }
      }
    },
    resizeColumn: function resizeColumn(index, delta) {
      var columns = Ember.get(this, 'root.subcolumnNodes');
      var fillColumn = columns[index];
      (false && !(fillColumn) && Ember.assert("Invalid column index, ".concat(index, ", for a table with ").concat(columns.length, " columns"), fillColumn));
      var oldWidth = Ember.get(fillColumn, 'width');
      Ember.set(fillColumn, 'width', oldWidth + delta);
    },
    getReorderBounds: function getReorderBounds(node) {
      var parent = Ember.get(node, 'parent');
      var scale = this.scale;
      var scrollLeft = this.container.scrollLeft;

      var _getInnerClientRect2 = (0, _element.getInnerClientRect)(this.container),
          containerLeft = _getInnerClientRect2.left;

      var leftBound, rightBound, nodes;

      if (Ember.get(parent, 'isRoot')) {
        var isFixed = Ember.get(node, 'isFixed');

        if (isFixed === 'left') {
          nodes = Ember.get(this, 'leftFixedNodes');
        } else if (isFixed === 'right') {
          nodes = Ember.get(this, 'rightFixedNodes');
        } else {
          nodes = Ember.get(this, 'unfixedNodes');
        }
      } else {
        nodes = Ember.get(node, 'parent.subcolumnNodes');
      }

      if (false
      /* DEBUG */
      ) {
        var firstReorderableFound = false;
        var lastReorderableFound = false;
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = nodes[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var _node = _step4.value;

            if (lastReorderableFound && Ember.get(_node, 'isReorderable')) {
              (false && !(false) && Ember.assert('Non-reorderable columns may only be contiguous segments at the beginning or end of their section (i.e. node middle columns in a list).', false));
            } else if (!firstReorderableFound && Ember.get(_node, 'isReorderable')) {
              firstReorderableFound = true;
            } else if (firstReorderableFound && !lastReorderableFound && !Ember.get(_node, 'isReorderable')) {
              lastReorderableFound = true;
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      }

      var reorderableNodes = nodes.filter(function (n) {
        return Ember.get(n, 'isReorderable');
      });
      var left = (0, _element.getOuterClientRect)(reorderableNodes[0].element).left;
      var right = (0, _element.getOuterClientRect)(reorderableNodes[reorderableNodes.length - 1].element).right;
      leftBound = (left - containerLeft) * scale + scrollLeft;
      rightBound = (right - containerLeft) * scale + scrollLeft;
      return {
        leftBound: leftBound,
        rightBound: rightBound
      };
    },
    registerContainer: function registerContainer(container) {
      this.container = container;
      this.scale = (0, _element.getScale)(container);
      Ember.get(this, 'root').registerElement(container);

      _emberRafScheduler.scheduler.schedule('sync', this.ensureWidthConstraint.bind(this), this.token);
    },
    getClosestColumn: function getClosestColumn(column, left, isFixed) {
      // If the column is fixed, adjust finder method and offset by the scroll
      // position since the column will appear stationary
      if (isFixed === 'left') {
        left -= this.container.scrollLeft;
      } else if (isFixed === 'right') {
        left += this.container.scrollWidth;
        left -= this.container.scrollLeft;
        left -= (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      var subcolumns = Ember.get(column.parent, 'subcolumnNodes');
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = subcolumns[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _column = _step5.value;
          var offset = Ember.get(_column, 'offsetLeft');

          if (left < offset + Ember.get(_column, 'width')) {
            return _column;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      return subcolumns[subcolumns.length - 1];
    },
    getClosestColumnOffset: function getClosestColumnOffset(column, left, isFixed) {
      var closestColumn = this.getClosestColumn(column, left, isFixed);
      var offsetLeft = Ember.get(closestColumn, 'offsetLeft'); // If the column is fixed, readjust the offset so that it's correct within
      // the container

      if (isFixed === 'left') {
        offsetLeft += this.container.scrollLeft;
      } else if (isFixed === 'right') {
        offsetLeft -= this.container.scrollWidth;
        offsetLeft += this.container.scrollLeft;
        offsetLeft += (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      return offsetLeft;
    },
    insertAfterColumn: function insertAfterColumn(parent, after, insert) {
      if (insert === after) {
        return;
      }

      var subcolumns = Ember.get(parent, 'column.subcolumns');
      var afterColumn = Ember.get(after, 'column');
      var insertColumn = Ember.get(insert, 'column');
      var afterIndex = subcolumns.indexOf(afterColumn);
      var insertIndex = subcolumns.indexOf(insertColumn);
      (0, _array.move)(subcolumns, insertIndex, afterIndex);
      (0, _ember.notifyPropertyChange)(subcolumns, '[]');
    },
    startReorder: function startReorder(node, clientX) {
      this.clientX = clientX;
      var bounds = this.getReorderBounds(node);
      this._reorderMainIndicator = new _reorderIndicators.MainIndicator(this.container, node.element, bounds);
      this._reorderDropIndicator = new _reorderIndicators.DropIndicator(this.container, node.element, bounds);
      this.container.classList.add('is-reordering');
    },
    updateReorder: function updateReorder(node, clientX) {
      this.clientX = clientX;

      this._updateReorder(node);

      if (!Ember.get(node, 'isFixed')) {
        this.updateScroll(node, true, true, this._updateReorder.bind(this));
      }
    },
    _updateReorder: function _updateReorder(node) {
      var scrollLeft = this.container.scrollLeft;
      var realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      var offset = this.clientX * this.scale - realContainerLeft + scrollLeft;
      var width = Ember.get(node, 'width');
      var newLeft = offset - width / 2;
      this._reorderMainIndicator.left = newLeft;
      this._reorderDropIndicator.left = this.getClosestColumnOffset(node, offset, Ember.get(node, 'isFixed'));
      this._reorderDropIndicator.width = Ember.get(this.getClosestColumn(node, this._reorderDropIndicator.left, Ember.get(node, 'isFixed')), 'width');
    },
    endReorder: function endReorder(node) {
      var scrollLeft = this.container.scrollLeft;
      var realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      var offset = this.clientX * this.scale - realContainerLeft + scrollLeft;

      var _this$getReorderBound = this.getReorderBounds(node),
          leftBound = _this$getReorderBound.leftBound,
          rightBound = _this$getReorderBound.rightBound;

      offset = Math.max(leftBound, offset);
      offset = Math.min(rightBound - 1, offset);
      var closestColumn = this.getClosestColumn(node, offset, Ember.get(node, 'isFixed'));
      this.insertAfterColumn(node.parent, closestColumn, node);

      this._reorderMainIndicator.destroy();

      this._reorderDropIndicator.destroy();

      this._reorderMainIndicator = null;
      this._reorderDropIndicator = null;

      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-reordering');
      this.sendAction('onReorder', Ember.get(node, 'column'), Ember.get(closestColumn, 'column'));
    },
    startResize: function startResize(node, clientX) {
      this.clientX = clientX;
    },
    updateResize: function updateResize(node, clientX) {
      var delta = Math.floor(Ember.get(node, 'isFixed') === 'right' ? (this.clientX - clientX) * this.scale : (clientX - this.clientX) * this.scale);
      this.clientX = clientX;

      if (Math.abs(delta) < 1) {
        return;
      } // Add the class after at least one update has occured


      this.container.classList.add('is-resizing');

      this._updateResize(node, delta);
    },
    _updateResize: function _updateResize(node, delta) {
      var resizeMode = Ember.get(this, 'resizeMode');
      var oldWidth = Ember.get(node, 'width');
      var minWidth = Ember.get(node, 'minWidth');
      delta = Math.max(oldWidth + delta, minWidth) - oldWidth;

      if (resizeMode === RESIZE_MODE.FLUID) {
        var parent = Ember.get(node, 'parent');
        var child = node;
        var sibling;

        while (parent && !sibling) {
          var siblings = Ember.get(parent, 'subcolumnNodes');
          sibling = siblings[siblings.indexOf(child) + 1];
          child = parent;
          parent = Ember.get(child, 'parent');
        }

        if (sibling) {
          var siblingOldWidth = Ember.get(sibling, 'width');
          var siblingMinWidth = Ember.get(sibling, 'minWidth');
          delta = -(Math.max(siblingOldWidth - delta, siblingMinWidth) - siblingOldWidth);
          Ember.set(sibling, 'width', siblingOldWidth - delta);
        } else {
          delta = 0;
        }
      }

      var newWidth = oldWidth + delta;
      Ember.set(node, 'width', newWidth);
      this.ensureWidthConstraint.call(this);
    },
    endResize: function endResize(node) {
      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-resizing');
      this.sendAction('onResize', Ember.get(node, 'column'));
    },
    updateScroll: function updateScroll(node, stopAtLeft, stopAtRight, callback) {
      var _this2 = this;

      if (this._nextUpdateScroll) {
        return;
      }

      this._nextUpdateScroll = _emberRafScheduler.scheduler.schedule('sync', function () {
        _this2._nextUpdateScroll = null;
        var container = _this2.container;
        var clientX = _this2.clientX;
        var _this2$container = _this2.container,
            scrollLeft = _this2$container.scrollLeft,
            scrollWidth = _this2$container.scrollWidth;

        var _EmberGet = Ember.get(_this2, 'scrollBounds'),
            containerLeft = _EmberGet.containerLeft,
            containerRight = _EmberGet.containerRight;

        var containerWidth = (0, _element.getOuterClientRect)(_this2.container).width * _this2.scale;

        var distanceToLeft = Math.max(clientX - containerLeft, 2);
        var distanceToRight = Math.max(containerRight - clientX, 2);
        var canScrollLeft = !stopAtLeft || scrollLeft !== 0;
        var canScrollRight = !stopAtRight || scrollLeft < scrollWidth - containerWidth;

        if (distanceToLeft <= SCROLL_THRESHOLD && canScrollLeft || distanceToRight <= SCROLL_THRESHOLD && canScrollRight) {
          var delta;

          if (distanceToLeft <= SCROLL_THRESHOLD) {
            delta = -SCROLL_THRESHOLD / distanceToLeft;
          } else {
            delta = SCROLL_THRESHOLD / distanceToRight;
          }

          delta = Math.round(delta);
          container.scrollLeft += delta;

          _this2.updateScroll(node, stopAtLeft, stopAtRight, callback);

          callback(node, delta);
        }
      }, this.token);
    }
  });

  _exports.default = _default;
});