define('ember-intercom-io/services/intercom', ['exports', 'intercom'], function (exports, _intercom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      merge = Ember.merge,
      Service = Ember.Service,
      computed = Ember.computed,
      assert = Ember.assert,
      scheduleOnce = Ember.run.scheduleOnce;
  exports.default = Service.extend({
    api: _intercom.default,

    _userNameProp: computed('config.userProperties.nameProp', function () {
      return get(this, 'user.' + get(this, 'config.userProperties.nameProp'));
    }),

    _userEmailProp: computed('config.userProperties.emailProp', function () {
      return get(this, 'user.' + get(this, 'config.userProperties.emailProp'));
    }),

    _userCreatedAtProp: computed('config.userProperties.createdAtProp', function () {
      return get(this, 'user.' + get(this, 'config.userProperties.createdAtProp'));
    }),

    user: {
      name: null,
      email: null
    },

    _hasUserContext: computed('user', '_userNameProp', '_userEmailProp', '_userCreatedAtProp', function () {
      return !!get(this, 'user') && !!get(this, '_userNameProp') && !!get(this, '_userEmailProp');
    }),

    _intercomBootConfig: computed('_hasUserContext', function () {
      var appId = get(this, 'config.appId');
      assert('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', appId);

      var obj = {
        app_id: appId // eslint-disable-line
      };

      if (get(this, '_hasUserContext')) {
        obj.name = get(this, '_userNameProp');
        obj.email = get(this, '_userEmailProp');
        if (get(this, '_userCreatedAtProp')) {
          // eslint-disable-next-line
          obj.created_at = get(this, '_userCreatedAtProp');
        }
      }
      return obj;
    }),

    start: function start() {
      var _this = this;

      var bootConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _bootConfig = merge(get(this, '_intercomBootConfig'), bootConfig);
      scheduleOnce('afterRender', function () {
        return _this.get('api')('boot', _bootConfig);
      });
    },
    stop: function stop() {
      var _this2 = this;

      scheduleOnce('afterRender', function () {
        return _this2.get('api')('shutdown');
      });
    },
    update: function update() {
      var _this3 = this;

      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      scheduleOnce('afterRender', function () {
        return _this3.get('api')('update', properties);
      });
    }
  });
});