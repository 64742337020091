define('ember-toggle/components/x-toggle/component', ['exports', 'ember-toggle/components/x-toggle/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['x-toggle-component'],

    disabled: false,
    name: 'default',
    onLabel: 'On',
    offLabel: 'Off',
    value: false,

    // private
    toggled: Ember.computed.readOnly('value'),

    forId: Ember.computed(function () {
      return this.get('elementId') + '-x-toggle';
    }),

    actions: {
      sendToggle: function sendToggle(value) {
        var onToggle = this.get('onToggle');

        if (value !== this.get('value') && typeof onToggle === 'function') {
          onToggle(value);
        }
      }
    }
  });
});