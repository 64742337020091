define("ember-table/components/ember-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xeB5hzrJ",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"wrappedRows\"]]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[27,\"hash\",null,[[\"cells\",\"isHeader\",\"row\"],[[22,1,[\"cells\"]],[22,1,[\"isHeader\"]],[27,\"component\",[\"ember-tr\"],[[\"api\"],[[22,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"ember-tr\",null,[[\"api\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-thead/template.hbs"
    }
  });

  _exports.default = _default;
});