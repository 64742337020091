define("ember-cli-adapter-pattern/utils/required-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requiredMethod;

  /*
   * Utility method, returning a function that
   * throws an error unless defined. This is
   * like implementing abstract methods.
   *
   * @method requiredMethod
   *
   * @param {String} methodName
   *   Name of the required method.
   *
   * @return {Function}
   *   An 'abstract' method implementation.
   */
  function requiredMethod(methodName) {
    (false && !(methodName) && Ember.assert('Method name is required for requiredMethod.', methodName));
    return function () {
      throw new Ember.Error("Definition of method ".concat(methodName, " is required."));
    };
  }
});