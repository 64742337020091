define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0neTIPR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"table\"],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"api\",\"head\",\"body\",\"foot\"],[[23,[\"api\"]],[27,\"component\",[\"ember-thead\"],[[\"api\"],[[23,[\"api\"]]]]],[27,\"component\",[\"ember-tbody\"],[[\"api\"],[[23,[\"api\"]]]]],[27,\"component\",[\"ember-tfoot\"],[[\"api\"],[[23,[\"api\"]]]]]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-table/template.hbs"
    }
  });

  _exports.default = _default;
});