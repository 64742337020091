define("ember-cli-gravatar/templates/components/has-gravatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pxQHpHEO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,[\"checking\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"has\",\"image\"],[[23,[\"hasGravatar\"]],[27,\"component\",[\"gravatar-image\"],[[\"email\",\"secure\"],[[23,[\"email\"]],[23,[\"secure\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-gravatar/templates/components/has-gravatar.hbs"
    }
  });

  _exports.default = _default;
});