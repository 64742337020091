define('ember-drag-drop/components/sortable-objects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dragCoordinator: Ember.inject.service(),
    overrideClass: 'sortable-objects',
    classNameBindings: ['overrideClass'],
    enableSort: true,
    useSwap: true,
    inPlace: false,
    sortingScope: 'drag-objects',
    sortableObjectList: Ember.A(),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('enableSort')) {
        this.get('dragCoordinator').pushSortComponent(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('enableSort')) {
        this.get('dragCoordinator').removeSortComponent(this);
      }
    },
    dragStart: function dragStart(event) {
      event.stopPropagation();
      if (!this.get('enableSort')) {
        return false;
      }
      this.set('dragCoordinator.sortComponentController', this);
    },
    dragEnter: function dragEnter(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },
    dragOver: function dragOver(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },
    drop: function drop(event) {
      event.stopPropagation();
      this.set('dragCoordinator.sortComponentController', undefined);
      if (this.get('enableSort') && this.get('sortEndAction')) {
        this.get('sortEndAction')(event);
      }
    }
  });
});