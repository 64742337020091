(function() {
  function vendorModule() {
    'use strict';

    return {
      'default': {
        loadImage: self['loadImage'],
      }
    };
  }

  define('exif', [], vendorModule);
})();
