define('ember-intercom-io/components/intercom-io', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Component.extend({
    intercom: inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'intercom').start();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      get(this, 'intercom').stop();
    }
  });
});