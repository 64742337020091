define('ember-cli-gravatar/components/has-gravatar', ['exports', 'ember-cli-gravatar/templates/components/has-gravatar'], function (exports, _hasGravatar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      getProperties = Ember.getProperties,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend({
    gravatar: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'email', 'gravatar', 'secure'),
          email = _getProperties.email,
          gravatar = _getProperties.gravatar,
          secure = _getProperties.secure;

      return gravatar.hasGravatar(email, secure, 'www.gravatar.com').then(function (hasGravatar) {
        set(_this, 'hasGravatar', hasGravatar);
        set(_this, 'checking', false);
      });
    },


    layout: _hasGravatar.default,
    hasGravatar: false,
    checking: true,
    email: '',
    secure: true
  });
});