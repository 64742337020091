define("emberx-select/templates/components/x-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4Li0YMU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"option\"],[[27,\"component\",[\"x-option\"],[[\"select\",\"register\",\"unregister\"],[[22,0,[]],[27,\"action\",[[22,0,[]],\"registerOption\"],null],[27,\"action\",[[22,0,[]],\"unregisterOption\"],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "emberx-select/templates/components/x-select.hbs"
    }
  });

  _exports.default = _default;
});